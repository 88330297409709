<template>
    <AdminWrapper>
        <template v-slot:child>
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>

            <!--Greeting, stats, overview -->
            <div class="w-90 center mt5 pt0-l pt4 f4 b">Account Activities</div>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="pb3 pb0-l">
                    <p>
                        Here is what is going on with
                        <span class="b" style="color: #132C8C; text-transform: capitalize;">{{
                            businessPicked.text ? businessPicked.text : businessPicked ? businessPicked : '-'
                        }}</span>
                    </p>
                </div>
            </div>
            <!--Greeting, stats, overview -->

            <!-- filter and search -->
            <div class="w-90 center pt3">
                <div class="pv3 flex flex-wrap justify-between items-center">
                    <div class="w-25-l f5 mb0-l mb3" style="color:#132C8C;">
                        <SearchInput 
                            placeholder="Search businesses..." 
                            v-model="state.searchVal" 
                            @update:modelValue="search" 
                        />
                    </div>
                    <div class="flex flex-wrap items-end justify-between w-60-l w-100">
                        <div class="pl3-l mb0-l mb3 w-third-l w-50">
                            <div class="pb1 font-w1">Start date</div>
                            <input type="date" name="" class="input-style w-100" v-model="start" />
                            <!-- :disabled="currentBusinessID" -->
                        </div>
                        <div class="ph3-l mb0-l mb3 w-third-l w-50  pl3">
                            <div class="pb1 font-w1">End date</div>
                            <input type="date" name="" class="input-style w-100" v-model="end" @change="onChangeDate(end)" />
                            <!-- :disabled="currentBusinessID" -->
                        </div>
                        <div class="filter tc mb0-l mb3 w-third-l w-50" style="position:relative">
                            <div @click="toggleAttributeDropDown()">
                                <div class="pr2">
                                    <span class="pr1 v-mid"><img :src="require('@/assets/images/filter_list.svg')" alt=""/></span>
                                    Visible attributes
                                </div>
                            </div>
                            <div class="makeAbsolute" v-if="showAttribute">
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="bizName" @click="toggleTableAttribute('bizName')" /><span
                                        class="ml2"
                                        >Business Name</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="bizEmail" @click="toggleTableAttribute('bizEmail')" /><span
                                        class="ml2"
                                        >Email</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="phoneNo" @click="toggleTableAttribute('phoneNumber')" /><span
                                        class="ml2"
                                        >Phone No.</span
                                    >
                                </div>
                                <!-- <div class="makeBBottom">
                                    <input
                                        type="checkbox"
                                        v-model="bizOwnerName"
                                        @click="toggleTableAttribute('bizOwnerName')"
                                    /><span class="ml2">Business Owner Name</span>
                                </div>
                                <div class="makeBBottom">
                                    <input
                                        type="checkbox"
                                        v-model="bizOwnerEmail"
                                        @click="toggleTableAttribute('bizOwnerEmail')"
                                    /><span class="ml2">Business Owner Email</span>
                                </div> -->
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="bizSource" @click="toggleTableAttribute('bizSource')" /><span
                                        class="ml2"
                                        >Source</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="bizModel" @click="toggleTableAttribute('bizModel')" /><span
                                        class="ml2"
                                        >Business Model</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="typeBiz" @click="toggleTableAttribute('typeBiz')" /><span
                                        class="ml2"
                                        >Business Type</span
                                    >
                                </div>
                                <!-- <div class="makeBBottom">
                                    <input type="checkbox" v-model="bizRole" @click="toggleTableAttribute('bizRole')" /><span
                                        class="ml2"
                                        >Role</span
                                    >
                                </div> -->
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="noOfUsers" @click="toggleTableAttribute('noOfUsers')" /><span
                                        class="ml2"
                                        >No of Users</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="phoneNo" @click="toggleTableAttribute('plans')" /><span
                                        class="ml2"
                                        >Plans</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input
                                        type="checkbox"
                                        v-model="bizPartner"
                                        @click="toggleTableAttribute('bizPartner')"
                                    /><span class="ml2">Partner</span>
                                </div>
                                <div class="makeBBottom">
                                    <input type="checkbox" v-model="createdAt" @click="toggleTableAttribute('createdAt')" /><span
                                        class="ml2"
                                        >Created Date</span
                                    >
                                </div>
                                <div class="makeBBottom">
                                    <input
                                        type="checkbox"
                                        v-model="lastActivity"
                                        @click="toggleTableAttribute('lastActivity')"
                                    /><span class="ml2">Last Activity</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- filter and search -->

            <!-- Attributes-->
            <div class="scrollmenu w-90 center pt2">
                <table class="w-100 tl pt3 bt b--black-20" cellspacing="0">
                    <thead>
                        <tr>
                            <th
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'name')"
                                v-if="bizName"
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                style="cursor:pointer"
                            >
                                Business <br />Name
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'bizEmail')"
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                style="cursor:pointer"
                                v-if="bizEmail"
                            >
                                Email
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th v-if="phoneNo" class="pb3 bb b--black-20 pr3 tl lh-title">
                                Phone No
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    style="cursor:pointer"
                                    alt=""
                                />
                            </th>
                            <!-- <th
                                v-if="bizOwnerName"
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'bizOwnerName')"
                                style="cursor:pointer"
                            >
                                Primary Business <br />Owner Name
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                />
                            </th>
                            <th class="pb3 bb b--black-20 pr3 tl lh-title" v-if="bizOwnerEmail">
                                Primary Business <br />Owner Email
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                />
                            </th> -->
                            <th
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                v-if="bizSource"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'bizSource')"
                            >
                                Source
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                v-if="bizModel"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'bizModel')"
                            >
                                Business Model
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                v-if="typeBiz"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'typeBiz')"
                            >
                                Type of <br />Business
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <!-- <th class="pb3 bb b--black-20 pr3 tl lh-title" v-if="bizRole">
                                Role
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                />
                            </th> -->
                            <th
                                class="pb3 bb b--black-10 pr3 tl lh-title"
                                v-if="noOfUsers"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'noOfUsers')"
                                style="cursor:pointer"
                            >
                                No of <br />User
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <!-- <th
                                class="pb3 bb b--black-10 pr3 tl lh-title"
                                v-if="plans"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'plans')"
                                style="cursor:pointer"
                            >
                                Plans
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                />
                            </th> -->
                            <th
                                class="pb3 bb b--black-10 pr3 tl lh-title"
                                v-if="bizPartner"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'bizPartner')"
                                style="cursor:pointer"
                            >
                                Partner
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                v-if="createdAt"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'createdAt')"
                                style="cursor:pointer"
                            >
                                Created <br />Date
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                            <th
                                class="pb3 bb b--black-20 pr3 tl lh-title"
                                v-if="lastActivity"
                                @click="sortPartnerTable(isOpen ? 'desc' : 'asc', 'lastActivity')"
                                style="cursor:pointer"
                            >
                                Last <br />Activity
                                <img
                                    :src="require('@/assets/images/sort-up.png')"
                                    :class="isOpen ? 'rotate' : ''"
                                    class="directional-up"
                                    alt=""
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="font-w1" v-for="biz in business.data" :key="biz._id">
                            <!-- {{
                                biz
                            }} -->
                            <td class="pv3 pr3 bb b--black-20 tl makeBold" v-if="bizName">
                                <router-link :to="{ name: 'AdminAccountDetails', params: { id: biz._id } }">{{
                                    biz?.name
                                }}</router-link>
                            </td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="bizEmail">{{ biz.orgEmail }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="phoneNo">{{ biz?.phoneNumber }}</td>
                            <!-- <td class="pv3 pr3 bb b--black-20 tl" v-if="bizOwnerName">{{ biz?.owner?.name || '-' }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="bizOwnerEmail">{{ biz?.owner?.email || '-' }}</td> -->
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="bizSource">{{ biz.lead }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="bizModel">{{ biz.business }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="typeBiz">{{ biz.typeOfBusiness }}</td>
                            <td class="pv3 pr3 bb b--black-10 tl" v-if="noOfUsers">-</td>
                            <td class="pv3 pr3 bb b--black-10 tl" v-if="plans">-</td>
                            <td class="pv3 pr3 bb b--black-10 tl" v-if="bizPartner">-</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="createdAt">{{ formatDate(biz.createdAt) }}</td>
                            <td class="pv3 pr3 bb b--black-20 tl" v-if="lastActivity">{{ biz.lastActivityDate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <Pagination
                :limit="$route.query.limit"
                :pages="business?.pagination"
                route-name="AdminAccount"
                :onNavigate="onNavigate"
            /> -->
           
            <div class="scrollmenu w-90 center pt2">
                <Page
                    v-model="business.page"
                    :total-row="business?.total"
                    @change="onPageChange"
                    language="en"
                />
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { reactive, ref } from 'vue'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { sortByNumericData, sortAlphaNumericData, sortByDate, formatDateFilter, formatDate } from '@/utils/lib'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import { computed, onMounted } from '@vue/runtime-core'
import Spinner from '@/components/Spinner'
import Pagination from '@/components/Pagination'
import SearchInput from 'vue-search-input'
import { Page } from 'v-page'
export default {
    components: {
        AdminWrapper,
        Spinner,
        Pagination,
        SearchInput,
        Page,
    },
    setup() {
        const route = useRoute()
        const store = useStore()
        const partners = computed(() => store.state.Admin.partners)
        const business = computed(() => store.state.Admin.business)
        const businessPicked = ref('')
        const getNameOnload = ref()
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth()
        const start = ref(formatDateFilter('2000-01-01'))
        const end = ref(formatDateFilter(new Date()))
        const showAttribute = ref(false)
        const isOpen = ref(false)
        const currentBusinessID = ref('')
        // table toggles
        const bizName = ref(true)
        const phoneNo = ref(false)
        const bizOwnerName = ref(true)
        const bizOwnerEmail = ref(true)
        const noOfUsers = ref(true)
        const plans = ref(false)
        const createdAt = ref(false)
        const lastActivity = ref(false)
        const typeBiz = ref(true)
        const bizRole = ref(false)
        const bizEmail = ref(true)
        const bizSource = ref(false)
        const bizModel = ref(true)
        const bizPartner = ref(false)
        const currentPage = ref(1);

        const state = reactive({
            searchVal: '',
        })

        const toggleAttributeDropDown = () => {
            showAttribute.value = !showAttribute.value
        }

        const toggleTableAttribute = name => {
            if (name === 'bizName') {
                bizName.value = !bizName.value
            }

            if (name === 'phoneNo') {
                phoneNo.value = !phoneNo.value
            }

            if (name === 'bizOwnerName') {
                bizOwnerName.value = !bizOwnerName
            }

            if (name === 'bizOwnerEmail') {
                bizOwnerEmail.value = !bizOwnerEmail
            }

            if (name === 'noOfUsers') {
                noOfUsers.value = !noOfUsers.value
            }

            // if (name === 'plans') {
            //     plans.value = !plans.value
            // }

            if (name === 'createdAt') {
                createdAt.value = !createdAt.value
            }

            if (name === 'lastActivity') {
                lastActivity.value = !lastActivity.value
            }

            if (name === 'typeBiz') {
                typeBiz.value = !typeBiz.value
            }

            if (name === 'bizRole') {
                bizRole.value = !bizRole.value
            }

            if (name === 'bizEmail') {
                bizEmail.value = !bizEmail.value
            }

            if (name === 'bizModel') {
                bizModel.value = !bizModel.value
            }

            if (name === 'bizSource') {
                bizSource.value = !bizModel.value
            }

            if (name === 'bizPartner') {
                bizPartner.value = !bizPartner.value
            }
        }

        const onChange = e => {
            // console.log('get', e)
            if (e === 'all') {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }

                store.dispatch('Admin/getAccountAllBusinessData', payload)
            } else {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
                store.dispatch('Admin/getPartnerBusiness', { id: e, payload })
            }
            currentBusinessID.value = e
        }

        const search = async () => {
            if (state.searchVal !== '' && state.searchVal.length < 3) {
                return;
            }
            await store.dispatch('Admin/getPartners')
            checkifAll(true)
        }

        onMounted(async () => {
            const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
            store.dispatch('Admin/getAccountActivities', { id: 200, payload })
        })

        const onChangeDate = () => {
            if (businessPicked.value.text === undefined) {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }

                store.dispatch('Admin/getAccountAllBusinessData', payload)
            } else {
                const payload = {
                    page: route.query.page || 1,
                    limit: route.query.limit || 25,
                    filterType: 'adminRange',
                    start: start.value,
                    end: end.value
                }
                store.dispatch('Admin/getPartnerBusiness', { id: currentBusinessID.value, payload: payload })
            }
        }

        const onNavigate = query => {

            const payload = {
                // page: route.query.page,
                limit: query,
                filterType: 'adminRange',
                startDate: start.value,
                endDate: end.value
            }
            // console.log('payload', payload)
            store.dispatch('Admin/getAccountAllBusinessData', query)
        }

        const onPageChange = page => {
            const query = {
                limit: page?.pageSize || 25,
                page: page?.pageNumber || 1,
                filterType: 'adminRange',
                startDate: start.value,
                endDate: end.value
            }
            store.dispatch('Admin/getAccountAllBusinessData', query)
        }

        const sortPartnerTable = (order, value) => {
            const query = {
                data: business.value,
                order: order,
                value: value
            }

            switch (value) {
                case 'noOfUsers':
                    sortByNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'name':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizSource':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizModel':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizOwnerName':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'typeBiz':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizOwnerEmail':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'createdAt':
                    sortByDate(query)
                    isOpen.value = !isOpen.value
                    break
                case 'lastActivity':
                    sortByDate(query)
                    isOpen.value = !isOpen.value
                    break
                // case 'plans':
                //     sortByDate(query)
                //     isOpen.value = !isOpen.value
                //     break
                case 'typeBiz':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value
                    break
                case 'bizRole':
                    sortAlphaNumericData(query)
                    isOpen.value = !isOpen.value

                    break
                default:
                    break
            }
        }

        const checkifAll = (isSearch=false) => {
            const payload = {
                page: route.query.page || 1,
                limit: route.query.limit || 25,
                filterType: 'adminRange',
                start: start.value,
                end: end.value
            }

            if (isSearch) {
                payload.q = state.searchVal
            }

            if (store.state.Admin.adminUserData.userType === 'admin') {
                store.dispatch('Admin/getAccountAllBusinessData', payload)
                businessPicked.value = 'all'
            } else {
                const partner = store.state.Admin.adminUserData.partner;
                businessPicked.value = { id: partner?._id, text: partner?.name };
                currentBusinessID.value = partner?._id
                store.dispatch('Admin/getPartnerBusiness', { id: partner?._id, payload })
            }
        }

        const initializeYearlyInventoryReport = () => {
            const today = new Date();
            const startDate = moment(today).format('YYYY-MM-DD');
            const currentYear = today.getFullYear()
            const endDate = `${currentYear}-12-31`;
            store.dispatch('Admin/initializeYearlyInventoryReport', {startDate, endDate})
        }

        return {
            state,
            sortPartnerTable,
            businessPicked,
            getNameOnload,
            onChange,
            partners,
            end,
            start,
            showAttribute,
            toggleAttributeDropDown,
            toggleTableAttribute,
            bizName,
            phoneNo,
            bizOwnerName,
            bizOwnerEmail,
            noOfUsers,
            plans,
            createdAt,
            lastActivity,
            business,
            isOpen,
            year,
            month,
            today,
            onChangeDate,
            currentBusinessID,
            typeBiz,
            bizRole,
            onNavigate,
            onPageChange,
            checkifAll,
            bizEmail,
            bizSource,
            bizModel,
            bizPartner,
            formatDate,
            search,
            initializeYearlyInventoryReport,
            currentPage,
        }
    }
}
</script>

<style>
.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.makeAbsolute {
    position: absolute;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 1;
    width: 80%;
}

.makeBBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding: 8px 4px;
    display: flex;
    /* justify-content: center; */
}

.makeBBottom:hover {
    background: rgb(240, 241, 242);
}

.makeBold {
    font-weight: 700;
}
/* .pagination-container {
    display: flex;
    column-gap: 10px;
  }
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  .active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
  }
  .active-page:hover {
    background-color: #2988c8;
  } */
</style>
